
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" @close="open = false" :open="open">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-gray-900 rounded-lg px-5 pt-5 pb-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-700">
                <svg class="h-6 w-6" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 118.93" style="enable-background:new 0 0 122.88 118.93" xml:space="preserve"><g><path class="fill-current text-white" d="M113.72,7.59l0.18,0.18c-1.32-0.78-3.16-1.05-5.46-1.05c-9.82,0-20.43,6.64-22.68,16.59 c-1.34,1.67-2.87,2.73-4.6,3.17c-4.99,1.28-7.72-2.09-11.51-4.37c-2.1-1.26-4.2-2.15-6.64-2.52c-1.77-0.27-3.63-0.28-5.6,0.04 c-21.9,3.52-21.23,24.5-10.27,38.58c3.2,4.11,6.97,8.13,10.29,11.96c3.41,3.94,6.81,7.58,7.54,11.24c1.01,5.06-2.95,8.86-7.64,7.83 c-4.2-0.92-9.77-4.16-15.75-7.9L20.02,68.89c-4-2.47-7.76-3.47-11.27-2.88c-6.52,1.1-9.87,6.76-8.41,13.13 c2.36,10.28,14.79,15.74,13.89,27.66c-0.34,4.57-3.06,8.61-8.08,12.12c1.37,0.06,2.92-0.5,4.65-1.68c1.81-1.23,3.25-2.48,4.35-3.83 c6.15-7.5,2.21-16.4-1.87-23.55c-0.88-1.55-1.67-3.2-2.14-5.26c-1.11-4.86,0.95-7.54,5.86-5.45c10.97,4.67,34.26,27.62,46.94,26.25 c8.83-0.96,15.76-8.59,17.75-16.94c2.51-10.55-4.68-17.7-10.56-25.98c-3.83-5.39-7.83-10.62-11.51-16.12 c-1.17-1.85-0.86-3.82,0.21-5.17c3.1-3.91,6.62,0.05,9.66,1.65c3.15,1.66,6.36,2.5,9.41,2.82c7.88,0.84,16.44-2.92,21.72-8.76 c9.82-2.94,15.36-13.03,15.73-23.09c0.07-1.93-0.21-3.37-0.83-4.43l0.03,0.03c1.9-1.82,4.71-3.03,7.33-3.77 c-1.68-0.52-3.35-0.81-4.99-0.65c0.32-1.65,0.22-3.31-0.22-4.99C116.59,2.69,115.09,5.61,113.72,7.59L113.72,7.59z M111.53,20.13 c-0.88-0.88-2.32-0.88-3.2,0c-0.89,0.88-0.89,2.32,0,3.2c0.88,0.88,2.32,0.88,3.2,0C112.41,22.45,112.41,21.01,111.53,20.13 L111.53,20.13z M102.97,11.57c-0.89-0.89-2.32-0.89-3.2,0c-0.88,0.88-0.88,2.32,0,3.2c0.88,0.89,2.32,0.89,3.2,0 C103.86,13.89,103.86,12.46,102.97,11.57L102.97,11.57z"/></g></svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-100">
                  You've still got time
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-base text-gray-400">
                    Sneks will go live on 30th September. Make sure you don't miss out, join the newsletter.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-500 text-base font-medium text-white hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 sm:text-sm" @click="open = false">
                Back to the lobby
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { EmojiSadIcon } from '@heroicons/vue/outline'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
})

const open = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
})
</script>