<template>
  <div class="relative mt-20 py-16 lg:py-24">
    <div aria-hidden="true" class="hidden sm:block sm:absolute sm:inset-y-0 sm:right-0 sm:w-screen overflow-hidden">
      <div class="absolute inset-y-0 right-1/2 w-1/2 bg-gray-800 rounded-r-3xl"></div>
      <svg class="absolute top-8 left-2/3" width="404" height="392" fill="none" viewBox="0 0 404 392">
        <defs>
          <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-400" fill="currentColor"></rect>
          </pattern>
        </defs>
        <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"></rect>
      </svg>
    </div>
    <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="relative rounded-2xl px-6 pt-10 pb-44 bg-blue-500 overflow-hidden shadow-xl sm:px-12 sm:py-16">
        <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
          <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
            <path class="text-blue-400 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"></path>
            <path class="text-blue-600 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"></path>
          </svg>
          <div class="absolute bottom-0 inset-x-0 pointer-events-none">
            <img class="absolute -bottom-4 left-0 transform -scale-x-1 w-48 md:w-64" src="@/assets/img/sss/4.png">
            <img class="absolute -bottom-4 right-8 transform w-32 md:w-48" src="@/assets/img/sss/5.png">
          </div>
        </div>
        <div class="relative">
          <div class="sm:text-center">
            <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mb-4">
              Launching Soon
            </span>
            <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
              <span class="text-blue-900">Drop #1:</span> Space Sneks
            </h2>
            <p class="mt-6 mx-auto max-w-2xl text-lg text-blue-100">
              Sssss ss sssss sss s sss s ssssss. Sssss ss ssss s? Ss ssss s sss. 
            </p>
          </div>
          <div class="mt-12 flex justify-center space-x-5">
            <a href="https://sneks.diamondhandshotel.com" target="_blank" class="inline-flex items-center px-4 sm:px-6 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-900 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              View collection
              <ArrowRightIcon class="ml-3 -mr-1 h-5 w-5" aria-hidden="true" />
            </a>
            <button type="button" @click="open = true" class="inline-flex items-center px-4 sm:px-6 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-900 bg-opacity-50 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Buy one
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <ModalSoldOut v-model="open" /> -->
  <ModalNotLive v-model="open" />
</template>


<script setup>
import { ref } from 'vue';
import { ArrowSmRightIcon, ArrowRightIcon } from '@heroicons/vue/solid'
// import ModalSoldOut from './ModalSoldOut.vue'
import ModalNotLive from './ModalNotLive.vue'

const open = ref(false)
</script>