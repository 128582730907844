<template>
  <div class="relative mt-16 py-16 lg:py-24">
    <div aria-hidden="true" class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
      <svg class="absolute top-8 left-1/3" width="404" height="550" fill="none" viewBox="0 0 404 550">
        <defs>
          <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-400" fill="currentColor"></rect>
          </pattern>
        </defs>
        <rect width="404" height="550" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"></rect>
      </svg>
    </div>
    <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="relative rounded-2xl px-6 py-10 bg-purple-500 overflow-hidden shadow-xl sm:px-12 sm:py-16">
        <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
          <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
            <path class="text-purple-400 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"></path>
            <path class="text-purple-600 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"></path>
          </svg>
        </div>
        <div class="relative">
          <div class="sm:text-center">
            <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 mb-4">
              In Production
            </span>
            <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
              <span class="text-purple-900">Drop #2:</span>
              <span class="pl-4 filter blur">CLASSIFIED</span>
            </h2>
            <p class="mt-6 mx-auto max-w-2xl text-lg text-white filter blur-sm">
              Ah ah ah, you didn't say the magic word. Ah ah ah, you didn't say the magic word.
            </p>
          </div>
          <form v-if="!sent" @submit.prevent="notify" class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
            <div class="min-w-0 flex-1">
              <label for="cta-email" class="sr-only">Email address</label>
              <input id="cta-email" v-model="email" type="email" class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-purple-500" placeholder="Enter your email" required />
            </div>
            <div class="mt-4 sm:mt-0 sm:ml-3">
              <button type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-gray-900 text-base font-medium text-white shadow hover:bg-black focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-purple-500 sm:px-10">Notify me</button>
            </div>
          </form>
          <div v-else class="mt-12 text-center">
            <span class="inline-flex items-center px-5 py-3 rounded-md bg-purple-900 bg-opacity-50 text-base font-lg text-white">
              <BadgeCheckIcon class="inline mr-3 -ml-1 h-6 w-6" aria-hidden="true" />
              You're on the list
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref } from 'vue'
import { BadgeCheckIcon } from '@heroicons/vue/outline'
import { newsletterSignup } from '@/supabase'

const sent = ref(false)
const email = ref('')
const notify = async () => {
  const [success, message] = await newsletterSignup(email.value, 'drop_classified')
  if (success) sent.value = true
}
</script>